import Core from "./PlugMap/Core.js";
import Search from "./PlugMap/Plugins/Search/index";
import Themes from "./PlugMap/Plugins/Themes/index";
import Drawing from "./PlugMap/Plugins/Drawing/index";
import ColorUnderPointer from "./PlugMap/Plugins/ColorUnderPointer/index";
import Identify from "./PlugMap/Plugins/Identify/index";

window.renderMap = function(id,options) {
  
    //TODO: We cant handle multiple maps...
    if (window.famMap) window.famMap.destruct();
  
    const core = new Core(id);
    core.init({}, window.app, options);
  
    core.register([
      [Themes, {}],
      [Drawing, {}],
      [Search, {}],
      //[SWRMPlugin, {}]
      // [Upload, {}],
      // [FeaturePicker, {}],
      // [Extent, {}]
      //[ColorUnderPointer, {}],
      //[Identify, {}]
    ]);
  
    window.famMap = core;
  
  
    window.onresize = function()
    {
      if(window.refreshMapSize != undefined)
      {
        this.clearTimeout(window.refreshMapSize);
        window.refreshMapSize = undefined;
      }
      window.refreshMapSize = setTimeout( function() { 
        requestAnimationFrame(() => {
          var map = core.getMap();
          if(map)
            map.updateSize();
        })}, 200);
    }
  }

window.destroyMap = function() {
  if (window.famMap) window.famMap.destruct();
}